<template>
  <div>
    <SimpleLayout>
      <el-card>
        <el-row>
          <el-col :span="12">
            <el-form>
              <el-form-item label="公司名称">
                <el-input v-model="form.comp_title"></el-input>
              </el-form-item>
              <el-form-item label="公司地址">
                <el-input v-model="form.comp_address"></el-input>
              </el-form-item>
              <el-form-item label="默认绑定员工">
                <y-choose-staff v-model="form.default_use_staff_id"></y-choose-staff>
              </el-form-item>
              <el-form-item label="消息互动欢迎文本">
                <el-input v-model="form.im_welcome_text"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="edit" type="primary">提交</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </SimpleLayout>
  </div>
</template>

<script>
import SimpleLayout from "@/components/layouts/SimpleLayout";
import YChooseStaff from "@/components/card/y-choose-staff/YChooseStaff";
export default {
  name: "basic",
  components: {YChooseStaff, SimpleLayout},
  data(){
    return{
      form:{
        comp_title:"",
        comp_address:"",
        default_use_staff_id:0,
        im_welcome_text:"",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.$u.api.card.setting().then(res=>{
        this.form = res;
      })
    },
    edit(){
      this.$u.api.card.settingEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>

</style>